import LoadingButton from "@mui/lab/LoadingButton";
import {useCallback, useMemo, useState} from "react";
import {Form, FormikProvider, useFormikContext} from "formik";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {DesktopTimePicker} from "@mui/x-date-pickers/DesktopTimePicker";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Close from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import Editor from "../editor";
import SimpleLink from "../SimpleLink";
import PeriodSelector from "./PeriodSelector";
import {IconPickerField} from "../IconPicker";
import {ColorPickerField} from "../ColorPicker";
import {fData} from "../../../utils/formatNumber";
import {UploadAvatar, UploadMultiFile, UploadSingleFile} from "../upload";
import {FormLabel} from "@mui/material";

export function FormTitleAndCloseButton(
    {
        title, subTitle, onCLose,
        TitleProps = {},
        SubTitleProps = {},
        ...others
    }
) {
    const titleProps = {...{variant: "h4"}, ...TitleProps}
    const subTitleProps = {...{variant: "h4"}, ...SubTitleProps}
    return (
        <Box display={"flex"} alignItems={"start"} mb={3} {...others}>
            <Stack flexGrow={1} spacing={1}>
                <Typography {...titleProps}>{title}</Typography>
                <Typography {...subTitleProps}>{subTitle}</Typography>
            </Stack>

            <Tooltip title={"Fermer"}><IconButton onClick={onCLose}><Close/></IconButton></Tooltip>
        </Box>
    );
}

export function FormikSubmitCancelGroupButton(
    {
        isEdit,
        createLabel,
        editLabel,
        cancelLabel,
        onCancel,
        buttonType,
        buttonSize,
        sx
    }
) {
    const {isSubmitting} = useFormikContext();
    return (
        <SubmitCancelGroupButton {...{
            isEdit,
            isSubmitting,
            createLabel,
            editLabel,
            cancelLabel,
            onCancel,
            buttonType,
            buttonSize,
            sx
        }}/>
    )
}

export function SubmitCancelGroupButton(
    {
        isEdit, isSubmitting, createLabel, editLabel, cancelLabel,
        onCancel, buttonType = "submit", buttonSize = "medium", flexPosition = 'flex-end', sx
    }
) {
    return (
        <Box sx={{mt: 5, display: 'flex', justifyContent: flexPosition, ...sx}}>
            {onCancel && < LoadingButton
                sx={{mr: 2}}
                size={buttonSize}
                onClick={onCancel}
                loading={isSubmitting}
                disabled={isSubmitting}
            >
                {cancelLabel ?? 'Annuler'}
            </LoadingButton>}
            <LoadingButton type={buttonType} variant='contained' size={buttonSize} loading={isSubmitting}>
                {!isEdit ? (createLabel ?? 'Créer') : (editLabel ?? 'Modifier')}
            </LoadingButton>
        </Box>

    );
}

export function SubSectionTitle(
    {
        title, action, TypographyProps = {}, children, variant = "h4", ...others
    }
) {
    return (
        <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            sx={{pt: 4}} {...others}
        >
            <Typography variant={variant} {...TypographyProps}>{title}</Typography>

            {action}
        </Box>
    );
}

export function FormikForm({formik, children}) {
    const {handleSubmit} = formik;
    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                {children}
            </Form>
        </FormikProvider>
    )
}

export function SimpleFormikForm({formik, onCancel, isEdit, children}) {
    return (
        <FormikForm formik={formik}>
            <Card sx={{p: 3}}>
                <Stack spacing={3}>
                    {children}
                    <SubmitCancelGroupButton {...{onCancel, isEdit}} flexPosition={"start"}/>
                </Stack>
            </Card>
        </FormikForm>
    );
}

export function FormikSwitch({name, ...others}) {
    const {getFieldProps, values} = useFormikContext();

    return (
        <FormControlLabel
            sx={{mb: 2}}
            control={(
                <Switch {...getFieldProps(name)} checked={values[name]}/>
            )}
            {...others}
        />
    );
}

export function FormikTextField({name = "name", onKeyDown, ...others}) {
    const {getFieldProps, touched, errors} = useFormikContext();
    const errorMessage = touched[name] && errors[name];

    return (
        <TextField
            fullWidth
            {...getFieldProps(name)}
            helperText={errorMessage}
            error={Boolean(errorMessage)}
            onKeyDown={onKeyDown && onKeyDown}
            {...others}
        />
    );
}

export function FormikPasswordField({name = "password", ...others}) {

    const [showPassword, setShowPassword] = useState(false);

    const handleShowPassword = () => setShowPassword((show) => !show);

    return (
        <FormikTextField
            name={name}
            {...others}
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    );
}

export function FormikEditor({name = "name", ...others}) {
    const {values, setFieldValue, getFieldProps, touched, errors} = useFormikContext();
    const errorMessage = touched[name] && errors[name];

    return (
        <Editor
            id={name}
            value={values[name]}
            error={Boolean(errorMessage)}
            onChange={value => setFieldValue(name, value)}
            helperText={errorMessage && (
                <FormHelperText error sx={{px: 2}}>
                    {errorMessage}
                </FormHelperText>
            )}
            {...others}
        />
    );
}

export function FormikDatePicker({name, ...others}) {
    const {getFieldProps, setFieldValue, touched, errors} = useFormikContext();
    const errorMessage = touched[name] && errors[name];
    return (
        <DesktopDatePicker
            {...getFieldProps(name)}
            inputFormat='dd/MM/yyyy'
            mask="__/__/____"
            onChange={(newValue) => setFieldValue(name, newValue)}
            renderInput={(params) => (
                <TextField fullWidth {...params} helperText={errorMessage} error={Boolean(errorMessage)}/>
            )}
            {...others}
        />
    )
}

export function SimpleDatePicker({value, onChange, fullWidth, inputFormat = "dd/MM/yyyy", mask = "__/__/____", sx}) {
    return (
        <DesktopDatePicker
            mask={mask}
            value={value}
            onChange={onChange}
            inputFormat={inputFormat}
            renderInput={(params) => <TextField fullWidth={fullWidth} {...params} sx={{...sx}}/>}
        />
    )
}

export function FormikTimePicker({name, ...others}) {
    const {getFieldProps, setFieldValue, touched, errors} = useFormikContext();
    const errorMessage = touched[name] && errors[name];
    return (
        <DesktopTimePicker
            {...getFieldProps(name)}
            inputFormat="HH:mm"
            onChange={(newValue) => setFieldValue(name, newValue)}
            renderInput={(params) => (
                <TextField fullWidth {...params} helperText={errorMessage} error={Boolean(errorMessage)}/>
            )}
            {...others}
        />
    )
}

export function DatePickerField({name, value, inputFormat = "dd/MM/yyyy", onChange, ...others}) {
    return (
        <DesktopDatePicker
            value={value}
            inputFormat={inputFormat}
            onChange={(newValue) => onChange && onChange(newValue)}
            renderInput={(params) => <TextField fullWidth {...params}/>}
            {...others}
        />
    )
}

export function FormikSelectField({name, label, showInputLabel = true, children, ...others}) {
    const {getFieldProps, touched, errors} = useFormikContext();
    const errorMessage = touched[name] && errors[name];

    return (
        <FormControl fullWidth>
            {showInputLabel && <InputLabel id={name}>{label}</InputLabel>}
            <Select
                label={label}
                labelId={name}
                placeholder={label}
                {...getFieldProps(name)}
                error={Boolean(errorMessage)}
                // helperText={errorMessage}
                {...others}
            >
                {children}
            </Select>
            {Boolean(errorMessage) && (
                <FormHelperText error sx={{px: 2}}>{errorMessage}</FormHelperText>
            )}
        </FormControl>
    );
}

export function FormikPeriodSelector({name = "period", ...others}) {
    const {setFieldValue, values, touched, errors} = useFormikContext();
    const errorMessage = touched[name] && errors[name];

    return (
        <PeriodSelector value={values[name]} onChange={value => setFieldValue(name, value)} {...others}/>
    );
}

export function FormikIconPickerField({name = "icon", ...others}) {
    const {setFieldValue, values, touched, errors} = useFormikContext();
    const errorMessage = touched[name] && errors[name];

    return (
        <IconPickerField value={values[name]} onChange={value => setFieldValue(name, value)} {...others}/>
    );
}

export function FormikColorPickerField({name = "color", ...others}) {
    const {setFieldValue, values, touched, errors} = useFormikContext();
    const errorMessage = touched[name] && errors[name];

    return (
        <ColorPickerField value={values[name]} onChange={value => setFieldValue(name, value)} {...others}/>
    );
}

export function SelectField({name, fullWidth = true, value, label, onChange, children, ...others}) {
    return (
        <FormControl fullWidth={fullWidth}>
            <InputLabel id={name}>{label}</InputLabel>
            <Select
                name={name}
                value={value}
                label={label}
                labelId={name}
                onChange={onChange}
                placeholder={label}
                {...others}
            >
                {children}
            </Select>
        </FormControl>
    )
}

export function FormikAutocomplete(
    {
        name, label, idField = 'id', labelField = 'name',
        embeddedObjField, options, query, setQuery, loading, InputProps, ...others
    }
) {
    const {values, setFieldValue, touched, errors} = useFormikContext();
    const errorMessage = touched[name] && errors[name];

    options = [{[idField]: null, [labelField]: `Selectionner - ${label}`}, ...options];

    const optionsIds = options.map(option => option[idField]);

    const id = values[name];
    const includesId = optionsIds.includes(id);

    return (
        <Autocomplete
            fullWidth
            name={name}
            autoHighlight
            options={options}
            inputValue={query}
            value={{[idField]: values[name] ?? null}}
            loadingText={<CircularProgress/>}
            isOptionEqualToValue={(option, value) => {
                const optionId = (option && option[idField]);
                const valueId = (value && value[idField]);
                // console.log(`optionId:${optionId}, valueId:${valueId}`);
                if (!optionId && !valueId) return true;

                if (loading && !optionId) return true;

                if (!includesId && !optionId) return true;

                return optionId === valueId;
            }}
            onChange={(event, selected) => {
                const selectedId = (selected && selected[idField]);
                // if (id && !includesId) {
                // } else {
                // }
                // embeddedObjField && (values[embeddedObjField] = selected);
                setFieldValue(name, selectedId);
                embeddedObjField && setFieldValue(embeddedObjField, selected);
                setQuery && setQuery((selected && selected[labelField]) ?? '');
            }}
            getOptionLabel={(option) => {
                const _option = options?.find(op => {
                    const optionId = (option && option[idField]);
                    const opId = (op && op[idField]);

                    if (!optionId && !opId) return true;

                    return optionId === opId;
                });
                const label = (_option && _option[labelField]) ?? '';

                return label;
            }}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        label={label}
                        helperText={errorMessage}
                        error={Boolean(errorMessage)}
                        onChange={({target: {value}}) => setQuery(value)}

                        {...InputProps}
                    />
                );
            }}
            {...others}
        />
    );
}

export function AutocompleteField(
    {
        value, onChange, onChangeObjet, label, idField = "id", labelField = "name",
        options, query, setQuery, loading, InputProps, ...others
    }
) {
    options = [{[idField]: null, [labelField]: `Selectionner - ${label}`}, ...options];

    const optionsIds = options.map(option => option[idField]);

    const includesId = optionsIds.includes(value);

    return (
        <Autocomplete
            fullWidth
            autoHighlight
            options={options}
            inputValue={query}
            value={{[idField]: value ?? null}}
            loadingText={<CircularProgress/>}
            isOptionEqualToValue={(option, value) => {
                const optionId = (option && option[idField]);
                const valueId = (value && value[idField]);
                if (!optionId && !valueId) return true;

                if (loading && !optionId) return true;

                if (!includesId && !optionId) return true;

                return optionId === valueId;
            }}
            onChange={(event, selected) => {
                const selectedId = (selected && selected[idField]);

                onChange && onChange(selectedId);
                onChangeObjet && onChangeObjet(selected);
                setQuery && setQuery((selected && selected[labelField]) ?? '');
            }}
            getOptionLabel={(option) => {
                const _option = options?.find(op => {
                    const optionId = (option && option[idField]);
                    const opId = (op && op[idField]);

                    if (!optionId && !opId) return true;

                    return optionId === opId;
                });
                const label = (_option && _option[labelField]) ?? '';


                return label;
            }}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        label={label}
                        onChange={({target: {value}}) => setQuery(value)}

                        {...InputProps}
                    />
                );
            }}
            {...others}
        />
    );
}

export function FormDisplayField({label = '', value = '', endAdornment, ...others}) {
    return (
        <TextField
            fullWidth
            label={label}
            value={value}
            InputProps={{readOnly: true, endAdornment}}
            {...others}
        />
    );
}

export function FormPasswordPasswordField({label = '', value = '', ...others}) {

    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => setShowPassword((show) => !show);

    return (
        <TextField
            fullWidth
            label={label}
            value={value}
            {...others}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                readOnly: true,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    );
}


export function FormDisplayLinkField({label = '', value = '', to = '#', ...others}) {
    return (
        <TextField
            fullWidth
            label={label}
            value={value}
            {...others}
            InputProps={{
                readOnly: true,
                endAdornment: (
                    <InputAdornment position='end'>
                        <SimpleLink href={to}>
                            <ArrowForward sx={{width: 28, height: 28}}/>
                        </SimpleLink>
                    </InputAdornment>
                )
            }}
        />
    );
}

export function FormRowStack({children, ...others}) {
    return (
        <Stack
            direction={{xs: "column", sm: "row"}}
            spacing={{xs: 3, sm: 2}}
            {...others}
        >
            {children}
        </Stack>
    );
}

export function FormikRadioGroup({name, label, row = true, valueName, labelName, options = [], ...others}) {

    const {getFieldProps, touched, errors} = useFormikContext();
    const errorMessage = touched[name] && errors[name];

    return (
        <Box flexGrow={1}  {...others}>

            <FormControl>
                {label && (
                    <FormLabel>{label}</FormLabel>
                )}

                <RadioGroup row={row} {...getFieldProps(name)}>
                    {options?.map((option) => (
                        <FormControlLabel
                            key={option.key}
                            value={option.value}
                            control={<Radio/>}
                            label={option.label}
                        />
                    ))}
                </RadioGroup>

                {Boolean(errorMessage) && (
                    <FormHelperText error sx={{px: 2}}>{errorMessage}</FormHelperText>
                )}
            </FormControl>
        </Box>
    )
}

const useUploadData = (name) => {
    const {values, errors, setFieldValue} = useFormikContext();
    const {[name]: data} = values;
    const errorMessage = errors[name];
    const checkError = Boolean(errorMessage);


    const handleDrop = useCallback((acceptedFiles) => {
        let file = acceptedFiles[0];
        if (file) {
            file = Object.assign(file, {
                preview: URL.createObjectURL(file),
            });
            setFieldValue(name, {file});
        }
    }, [name]);

    return {data, checkError, handleDrop, errorMessage}
}

export function FormikUploadAvatar({name, label, maxSize = 3145728, ...other}) {
    const {data, handleDrop, checkError, errorMessage} = useUploadData(name);

    return (
        <Box>
            <UploadAvatar
                accept={{'image/*': []}}
                error={checkError}
                {...other}
                file={data?.file}
                maxSize={maxSize}
                onDrop={handleDrop}
                helperText={label && (
                    <Typography
                        variant="caption"
                        sx={{
                            mt: 2,
                            mx: 'auto',
                            display: 'block',
                            textAlign: 'center',
                            color: 'text.secondary',
                        }}
                    >
                        {label}
                        {maxSize && (
                            <>
                                <br/> {`Taille max ${fData(maxSize)}`}
                            </>
                        )}
                    </Typography>
                )}
            />

            {checkError && (
                <FormHelperText error sx={{px: 2, textAlign: 'center'}}>
                    {errorMessage}
                </FormHelperText>
            )}
        </Box>
    )
}

export function FormikUploadSingleFile({name, label, maxSize = 3145728, ...other}) {
    const {data, handleDrop, checkError, errorMessage} = useUploadData(name);

    return (
        <Box>
            <UploadSingleFile
                accept={{'image/*': []}}
                error={checkError}
                {...other}
                file={data?.file}
                maxSize={maxSize}
                onDrop={handleDrop}
                helperText={label && (
                    <Typography
                        variant="caption"
                        sx={{
                            mt: 2,
                            mx: 'auto',
                            display: 'block',
                            textAlign: 'center',
                            color: 'text.secondary',
                        }}
                    >
                        {label}
                        {maxSize && (
                            <>
                                <br/> {`Taille max ${fData(maxSize)}`}
                            </>
                        )}
                    </Typography>
                )}
            />

            {checkError && (
                <FormHelperText error sx={{px: 2, textAlign: 'center'}}>
                    {errorMessage}
                </FormHelperText>
            )}
        </Box>
    )
}

export function FormikUploadMultiFile({name, label, maxSize = 3145728, showPreview, onDrop, ...other}) {
    const {values, errors, setFieldValue} = useFormikContext();
    const {[name]: data} = values;
    const errorMessage = errors[name];
    const checkError = Boolean(errorMessage);

    const _files = useMemo(() => data?.files ?? [], [data]);

    const handleDrop = useCallback((acceptedFiles) => {
        const files = [
            ..._files,
            ...acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            ),
        ];

        setFieldValue(name, {files});
        onDrop && onDrop(files);
    }, [name, _files]);

    const handleRemoveAll = useCallback(() => {
        const files = [];
        setFieldValue(name, {files});
    }, [name]);

    const handleRemove = useCallback((file) => {
        const files = _files.filter((_file) => _file !== file);

        setFieldValue(name, {files});
    }, [name, _files]);

    return (
        <Box>
            <UploadMultiFile
                imgWidth={120}
                imgHeight={120}
                showPreview={showPreview}
                accept={{'image/*': []}}
                error={checkError}
                {...other}
                files={_files}
                maxSize={maxSize}
                onDrop={handleDrop}
                onRemove={handleRemove}
                onRemoveAll={handleRemoveAll}
                onUpload={() => console.log('ON UPLOAD')}
                helperText={label && (
                    <Typography
                        variant="caption"
                        sx={{
                            mt: 2,
                            mx: 'auto',
                            display: 'block',
                            textAlign: 'center',
                            color: 'text.secondary',
                        }}
                    >
                        {label}
                        {maxSize && (
                            <>
                                <br/> {`Taille max ${fData(maxSize)} par fichier`}
                            </>
                        )}
                    </Typography>
                )}
            />

            {checkError && (
                <FormHelperText error sx={{px: 2, textAlign: 'center'}}>
                    {errorMessage}
                </FormHelperText>
            )}
        </Box>
    )
}


// SNG coding...
export function DisplaySwitch({label, value, mb = 2}) {
    return (
        <Box sx={{mb, display: 'flex', gap: '10p', alignItems: 'center'}}>
            <Switch checked={value} inputProps={{readOnly: true}}/>
            <Typography variant={"body1"}>{label}</Typography>
        </Box>
    );
}

// SNG coding...
export function DisplayCheck({label, value}) {
    return (
        <Box sx={{display: 'flex', gap: '10p', alignItems: 'center'}}>
            <Checkbox checked={value} inputProps={{readOnly: true}}/>
            <Typography variant={"body1"}>{label}</Typography>
        </Box>
    );
}

// SNG coding...
export function DisplayRadioGroup({label, value, options}) {
    return (
        <Box>
            <Typography variant={"body1"} sx={{ml: 2}}>{label}</Typography>
            <RadioGroup sx={{flexDirection: 'row'}} value={value}>
                {options.map((option) => (
                    <Box key={option.label} sx={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                        <Radio checked={value === option} inputProps={{readOnly: true}}/>
                        <Typography variant={"body1"}>{option.label}</Typography>
                    </Box>
                ))}
            </RadioGroup>
        </Box>
    );
}

