import {useMemo, useState} from "react";
import * as icons from "@mui/icons-material";
import {Close, MoreHoriz, Search} from "@mui/icons-material";
import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,
    Grid, IconButton, InputAdornment, OutlinedInput, Stack, TablePagination, Tooltip, Typography
} from "@mui/material";


import {usePagination} from './tables';
import {useOpen} from "../hooks/hooks.utils";

export const findIcon = (iconName) => {
  return icons[iconName];
}

export const iconNames = Object.keys(icons);

export default function IconPicker(
    {onSelect, ButtonIcon = MoreHoriz, tooltip = "Selectionner Icone"}
) {

    const [selectedIcon, setSelectedIcon] = useState();

    const {open, onOpen, onClose} = useOpen();

    const {
        page, query, rowsPerPage, handleChangePage, handleChangeRowsPerPage, setQuery
    } = usePagination({rowsPerPage: 50});

    const iconNamesFiltered = useMemo(() => {
        const _query = query.toLowerCase();
        return iconNames.filter(name => name.toLowerCase().includes(_query));
    }, [query]);

    const iconNamesFilteredPage = useMemo(() => {
        return iconNamesFiltered.slice(rowsPerPage * page, rowsPerPage * (page + 1));
    }, [iconNamesFiltered, rowsPerPage, page]);

    const Icon = selectedIcon && findIcon(selectedIcon);

    return (
        <>
            <Tooltip title={tooltip}>
                <IconButton onClick={onOpen}>
                    <ButtonIcon/>
                </IconButton>
            </Tooltip>

            {open && (
                <Dialog
                    open={open} maxWidth={"md"} onClose={onClose}
                >
                    <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <OutlinedInput
                            sx={{width: 340}}
                            value={query}
                            onChange={({target: {value}}) => setQuery(value)}
                            placeholder='Recherche'
                            startAdornment={
                                <InputAdornment position="start">
                                    <Search sx={{color: 'text.disabled'}}/>
                                </InputAdornment>
                            }
                        />


                        <Tooltip title={"Fermer"}>
                            <IconButton onClick={onClose}>
                                <Close/>
                            </IconButton>
                        </Tooltip>
                    </DialogTitle>
                    <DialogContent sx={{minHeight: "50vh", minWidth: "30vw"}}>
                        <Grid container spacing={2} mt={2}>
                            {iconNamesFilteredPage.map(iconName => (
                                <IconCard
                                    key={iconName} iconName={iconName}
                                    selectedIcon={selectedIcon} setSelectedIcon={setSelectedIcon}
                                />
                            ))}
                        </Grid>

                    </DialogContent>
                    <DialogActions sx={{
                        justifyContent: {
                            xs: "start",
                            md: "space-between",
                        },
                        alignItems: {
                            xs: "end",
                            md: "center",
                        },
                        flexDirection: {
                            xs: "column-reverse",
                            md: "row"
                        },
                    }}>
                        <TablePagination
                            component='div'
                            page={page ?? 0}
                            rowsPerPage={rowsPerPage}
                            count={iconNamesFiltered?.length ?? 0}
                            onPageChange={handleChangePage}
                            rowsPerPageOptions={[20, 50, 100]}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelDisplayedRows={({from, to, count}) => {
                                return `${from}–${to} sur ${count !== -1 ? count : `plus de ${to}`}`;
                            }}
                            labelRowsPerPage='Icones par page: '
                        />

                        <Box sx={{height: {xs: 10, md: 0}}}/>

                        <Button
                            disabled={!selectedIcon}
                            startIcon={selectedIcon && <Icon/>} variant={"contained"}
                            onClick={() => {
                                onClose();
                                onSelect && onSelect(selectedIcon);
                            }}
                        >
                            {"Selectionner"}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
}

function IconCard({iconName, selectedIcon, setSelectedIcon}) {
    const Icon = findIcon(iconName);
    return (
        <Grid
            item xs={6} sm={4} md={3} lg={2}
            onClick={() => {
                setSelectedIcon(iconName === selectedIcon ? null : iconName);
            }}
            sx={{
                my: .2,
                display: "flex", flexDirection: "column", alignItems: "center"
            }}
        >
            <Box gap={.8} sx={{
                width: "100%", height: "100%", overflow: "hidden",
                borderRadius: 1, p: 1, cursor: "pointer",
                display: "flex", flexDirection: "column", alignItems: "center",
                transition: theme => theme.transitions.create("all", {
                    easing: theme.transitions.easing.easeIn,
                }),
                ":hover": {
                    boxShadow: theme => theme.shadows["7"],
                },
                ":active": {
                    boxShadow: theme => theme.shadows["1"],
                },
            }}>
                <Icon/>
                <Typography fontSize={10} sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                }}>{iconName}</Typography>
            </Box>
        </Grid>
    );
}


export function IconPickerField(
    {label = "Icone", value, onChange, labelProps = {}, iconPickerProps = {}, iconProps = {}}
) {
    const [selectedIcon, setSelectedIcon] = useState();

    const _selectedIcon = value ?? selectedIcon;
    const _setSelectedIcon = onChange ?? setSelectedIcon;

    const Icon = findIcon(_selectedIcon);

    return (
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
            {!Icon && <Typography {...labelProps}>{label}</Typography>}
            {Icon && <Icon {...iconProps}/>}
            <IconPicker {...iconPickerProps} onSelect={_setSelectedIcon}/>
        </Stack>
    );
}

export function IconBox({iconName, iconColor, sx}) {
    const Icon = findIcon(iconName);

    if(!Icon) return null;

    return (
        <Icon sx={{color: iconColor?.colorCode, ...sx}}/>
    )
}