import {useState} from "react";
import {MenuItem, Stack, TextField, Typography} from "@mui/material";

import {SelectField} from "./FormComponents";
import {Enum} from "../../../utils/enum.utils";


export const PeriodUnit = Enum({
    day: {label: "Jour", day: true},
    week: {label: "Semaine", week: true},
    month: {label: "Mois", month: true},
    year: {label: "Année", year: true},
});

export default function PeriodSelector({
        label = "Periode", value, onChange, onBlur,
        labelProps = {}, valueProps = {}, unitSelectProps = {}, unitItemProps = {}
    }) {
    const [period, setPeriod] = useState({value: 1, unit: PeriodUnit.month.name});

    const _period = value ?? period;
    const _onChange = onChange ?? setPeriod;

    const handleChange = (name, isNumber) => ({target: {value}}) => {
        _onChange({
            ..._period,
            [name]: isNumber ? +value : value
        });
    }

    return (
        <Stack>
            <Typography {...labelProps}>{label}</Typography>
            <Stack direction={"row"}>
                <TextField
                    fullWidth value={_period.value} type={"number"}
                    onChange={handleChange("value", true)} {...valueProps}
                />
                <SelectField value={_period.unit} onChange={handleChange("unit")} {...unitSelectProps}>
                    {PeriodUnit.values().map(unit => (
                        <MenuItem key={unit.name} value={unit.name} {...unitItemProps}>
                            {unit.label}
                        </MenuItem>
                    ))}
                </SelectField>
            </Stack>
        </Stack>
    );
}



