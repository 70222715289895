import {Box, Typography, Stack} from "@mui/material";

import {UploadIllustration} from "../../../assets";

// ----------------------------------------------------------------------

export default function BlockContent() {
    return (
        <Stack
            spacing={2}
            alignItems="center"
            justifyContent="center"
            direction={{xs: 'column', md: 'row'}}
            sx={{width: 1, textAlign: {xs: 'center', md: 'left'}}}
        >
            <UploadIllustration sx={{width: 220}}/>

            <Box sx={{p: 3}}>
                <Typography gutterBottom variant="h5">
                    {"Selectionner ou déposer un fichier"}
                </Typography>

                <Typography variant="body2" sx={{color: 'text.secondary'}}>
                    {"Déposer ici ou cliquer "}
                    <Typography
                        variant="body2"
                        component="span"
                        sx={{color: 'primary.main', textDecoration: 'underline'}}
                    >
                        {"explorer"}
                    </Typography>
                    {" votre machine"}
                </Typography>
            </Box>
        </Stack>
    );
}
