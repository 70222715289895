import {useMemo, useState} from "react";
import * as colors from "@mui/material/colors";
import {Close, MoreHoriz, Search} from "@mui/icons-material";
import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid,
    IconButton, InputAdornment, OutlinedInput, Stack, TablePagination, Tooltip, Typography
} from "@mui/material";

import {useOpen} from "../hooks/hooks.utils";
import {usePagination} from './tables';


export const colorNames = Object.keys(colors);
export const shade = [
    "50",
    "100",
    "200",
    "300",
    "400",
    "500",
    "600",
    "700",
    "800",
    "900",
    "A100",
    "A200",
    "A400",
    "A700",
]
export default function ColorPicker(
    {onSelect, ButtonIcon = MoreHoriz, tooltip = "Selectionner Couleur"}
) {

    const [selectedColor, setSelectedColor] = useState();

    const {open, onOpen, onClose} = useOpen();

    const {
        page, query, rowsPerPage, handleChangePage, handleChangeRowsPerPage, setQuery
    } = usePagination({rowsPerPage: 50});

    const colorNamesFiltered = useMemo(() => {
        const _query = query.toLowerCase();
        return colorNames.filter(name => name.toLowerCase().includes(_query));
    }, [query]);

    const colorNamesFilteredPage = useMemo(() => {
        return colorNamesFiltered.slice(rowsPerPage * page, rowsPerPage * (page + 1));
    }, [colorNamesFiltered, rowsPerPage, page]);

    const color = selectedColor && colors[selectedColor?.color][selectedColor?.shade];

    return (
        <>
            <Tooltip title={tooltip}>
                <IconButton onClick={onOpen}>
                    <ButtonIcon/>
                </IconButton>
            </Tooltip>

            {open && (
                <Dialog open={open} maxWidth={"md"} onClose={onClose}>
                    <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <OutlinedInput
                            sx={{width: 340}}
                            value={query}
                            onChange={({target: {value}}) => setQuery(value)}
                            placeholder='Recherche'
                            startAdornment={
                                <InputAdornment position="start">
                                    <Search sx={{color: 'text.disabled'}}/>
                                </InputAdornment>
                            }
                        />


                        <Tooltip title={"Fermer"}>
                            <IconButton onClick={onClose}>
                                <Close/>
                            </IconButton>
                        </Tooltip>
                    </DialogTitle>

                    <DialogContent sx={{minHeight: "50vh", minWidth: "30vw"}}>
                        <Stack>
                            {colorNamesFilteredPage.map(colorName => (
                                <ColorGrid
                                    key={colorName} colorName={colorName}
                                    selectedColor={selectedColor} setSelectedColor={setSelectedColor}
                                />
                            ))}
                        </Stack>
                    </DialogContent>

                    <DialogActions sx={{
                        alignItems: {xs: "end", md: "center"},
                        flexDirection: {xs: "column-reverse", md: "row"},
                        justifyContent: {xs: "start", md: "space-between"},
                    }}>
                        <TablePagination
                            component='div'
                            page={page ?? 0}
                            rowsPerPage={rowsPerPage}
                            count={colorNamesFiltered?.length ?? 0}
                            onPageChange={handleChangePage}
                            rowsPerPageOptions={[20, 50, 100]}
                            labelRowsPerPage='Couleurs par page: '
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelDisplayedRows={({from, to, count}) => {
                                return `${from}–${to} sur ${count !== -1 ? count : `plus de ${to}`}`;
                            }}
                        />

                        <Box sx={{height: {xs: 10, md: 0}}}/>

                        <Button
                            variant={"outlined"}
                            disabled={!selectedColor}
                            startIcon={<ColorBox colorCode={color}/>}
                            onClick={() => {
                                onClose();
                                onSelect && onSelect(selectedColor);
                            }}
                        >
                            {"Selectionner"}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}

function ColorGrid({colorName, selectedColor, setSelectedColor}) {
    const color = colors[colorName];
    return (
        <Box mt={2}>
            <Typography fontSize={18}>{colorName}</Typography>
            <Grid container spacing={2}>
                {shade.map(_shade => {
                    const colorCode = color[_shade];
                    const isSelected = selectedColor?.color === colorName && selectedColor?.shade === _shade;
                    return (
                        <Grid
                            key={`${colorName}-${_shade}`}
                            item xs={4} sm={3} md={2} lg={2}
                            onClick={() => {
                                if (isSelected) {
                                    setSelectedColor(null);
                                    return;
                                }
                                setSelectedColor({color: colorName, shade: _shade, colorCode});
                            }}
                            sx={{
                                my: .2,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <Box gap={.8} sx={{
                                width: "100%",
                                height: "100%",
                                overflow: "hidden",
                                borderRadius: 1,
                                p: 1,
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                border: "1px solid ",
                                borderColor: isSelected ? colorCode : "white",
                                transition: theme => theme.transitions.create("all", {
                                    easing: theme.transitions.easing.easeIn,
                                }),
                                ":hover": {
                                    boxShadow: theme => theme.shadows["7"],
                                },
                                ":active": {
                                    boxShadow: theme => theme.shadows["1"],
                                },
                            }}>
                                <ColorBox colorCode={colorCode}/>
                                <Typography fontSize={10}>{_shade}</Typography>
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    );
}

export function ColorBox({colorCode}) {
    return (
        <Box sx={{borderRadius: 1, backgroundColor: colorCode, height: 30, width: 30}}/>
    )
}

export function ColorPickerField(
    {label = "Couleur", value, onChange, labelProps = {}, colorPickerProps = {}}
) {

    const [selectedColor, setSelectedColor] = useState();

    const _selectedColor = value ?? selectedColor;
    const _setSelectedColor = onChange ?? setSelectedColor;

    return (
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
            {!_selectedColor && <Typography {...labelProps}>{label}</Typography>}
            <ColorBox colorCode={_selectedColor?.colorCode}/>
            <ColorPicker {...colorPickerProps} onSelect={_setSelectedColor}/>
        </Stack>
    );
}

